import gql from 'graphql-tag'

export const fetchUpdatePricesMutation = gql(`
mutation (
    $cart_id: String!, $delivery_date: String!, $date_popup: String!, $note_for_driver: String, $payment_method: String!,
    $outlet_order_type: String!, $confirmation_email: String, $delivery_method: String!, $payment_method_title: String!, $order_reason: String!,
    $simulate_type: String, $ship_to_id: String, $sold_to_id: String , $package_products: [PackageProducts]
) {
  updatePrices(
    input: {
      cart_id: $cart_id
      delivery_date: $delivery_date
      date_popup: $date_popup
      payment_method: $payment_method
      outlet_order_type: $outlet_order_type
      confirmation_email: $confirmation_email
      delivery_method: $delivery_method
      payment_method_title: $payment_method_title
      note_for_driver: $note_for_driver
      order_reason: $order_reason
      simulate_type: $simulate_type
      ship_to_id: $ship_to_id
      sold_to_id: $sold_to_id
      package_products: $package_products
    }
  ) {
      success,
      message,
      can_proceed,
      status_message,
      minicart_status_message,
      payment_method,
      delivery_date,
      delivery_date_expired,
      order_type,
      delivery_method,
      ship_to_id,
      flexible_delivery,
      package_products_total,
      redemption_fee,
      multi_deposit_z099,
      package_products_discount,
      order_items {
        name,
        sku,
        packaging,
        volume,
        is_wishlist,
        image_main,
        row_total,
        custom_price,
        list_price,
        excise_duty,
        discounts,
        total_discounts,
        full_price,
        discounted_price,
        listprice_with_discount,
        row_total_list_price,
        row_total_discount,
        row_total_excise,
        row_total_without_vat,
        row_total_with_vat,
        quantity
      }
      cart {
        note_for_driver
        customer_order_number
        grand_total_basic_price
        grand_total_basic_price_tax
        grand_total_your_price
        grand_total_your_price_tax
        grand_total_multi_deposit
        available_payment_methods {
          code
          title
        }
        selected_payment_method {
          code
          title
        }
        progress_bar_data {
          iMinimumOrderPallet,
          iMaximumOrderPallet,
          iMinimumOrderWeight,
          iMaximumOrderWeight,
          iMinimumUnit,
          iMaximumUnit,
          iKegFlag,
          iCustomerType,
          iTotalCartWeight,
          iTotalCartPalletQty,
          iTotalQty,
          msg,
          canProceed
        }
      }
      total_base_price,
      total_your_price,
      total_quantity,
    }
  }
`)
